import Link from 'next/link';
import { useEffect, useState } from 'react';

const FloatingButtons = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // 화면 높이의 60% 위치에서 시작
  const initialPosition =
    typeof window !== 'undefined' ? window.innerHeight * 0.6 : 850;
  const buttonPosition = Math.max(initialPosition - scrollY, 100);

  // 페이지 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className="fixed right-0 z-50 hidden md:block"
      style={{ top: `${buttonPosition}px` }}
    >
      <div className="w-10 h-[532px] relative">
        <div className="w-10 h-[400px] left-0 top-0 absolute bg-[#d9d9d9]/0" />

        {/* 상단으로 이동하는 버튼 추가 */}
        <button
          onClick={scrollToTop}
          className="w-10 h-10 left-0 top-[400px] absolute bg-[#474747] flex items-center justify-center"
          aria-label="페이지 상단으로 이동"
        >
          <img
            src="/images/arrow_forward.svg"
            alt="위로 이동"
            width={20}
            height={20}
            className="rotate-90 brightness-0 invert"
          />
        </button>

        <Link
          href="https://www.youtube.com/@FADU.youtube/videos"
          className="w-10 h-10 left-0 top-[442px] absolute flex-col justify-start items-start inline-flex"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/youtube.svg" alt="youtube" width={40} height={40} />
        </Link>
        <Link
          href="https://www.linkedin.com/company/fadutec/"
          className="w-10 h-10 left-0 top-[484px] absolute"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-10 h-10 left-0 top-0 absolute bg-[#474747] flex items-center justify-center">
            <img
              src="/images/linkedin.svg"
              alt="linkedin"
              width={15.38}
              height={16.75}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FloatingButtons;
